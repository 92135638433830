import { FileSidebarMenu } from "../components/SidebarMenu"
import { ModeContainer } from "../containers/ModeContainer"
import { FileApplicationLayout } from "../layouts/FileApplicationLayout"
import { MainFileLayout } from "../layouts/MainFileLayout"
import { FileSidebarLayout } from "../layouts/SidebarLayout"

import styles from './Layout.module.css'

const FileViewer = () => {
    return <ModeContainer title={'browser'}>
        <FileApplicationLayout>
            <FileSidebarMenu
                key={'sidebarMenuLayout'}
            />
            <div
                key={'mainLayout'}
                className={styles.mainContainer}
            >
                <FileSidebarLayout
                    key={'sidebarContent'}
                />
                <MainFileLayout
                    key={'mainContent'}
                />
            </div>
        </FileApplicationLayout>
    </ModeContainer>
}

export {
    FileViewer,
}
