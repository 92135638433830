import { useAuth } from 'react-oidc-context'
import * as React from 'react'
import { getUserProfile } from '../types'
import ParticleSphere from '../components/ParticleSphere'
import { DartmouthIdent } from '../components/DartmouthIdent'
import styles from './Login.module.css'
import { Helmet } from 'react-helmet'
import { Translate, useTranslate } from '../containers/I18nContainer'

const LoginPrompt: React.FC<{ displayUserName?: boolean }> = ({ displayUserName = true }) => {
    const auth = useAuth()

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div><Translate id={'user.loading'} /></div>
        case "signoutRedirect":
            return <div><Translate id={'user.loading'} /></div>
    }

    if (auth.isLoading) {
        return <button
            key={'loginButton'}
            className={[styles.loginPromptButton, styles.clicked].join(' ')}
        ><Translate id={'user.loading'} /></button>
    }

    if (auth.error) {
        return <div>{auth.error.message}</div>
    }

    if (auth.isAuthenticated && auth.user) {
        const profile = getUserProfile(auth.user.access_token);
        return <div style={{ width: '100%', textAlign: "center" }}>
            {displayUserName &&
                <div>{profile.name ?? ''}</div>
            }
            <button
                className={styles.loginPromptButton}
                onClick={() => {
                    auth.signoutRedirect({
                        post_logout_redirect_uri: window.location.href,
                        redirectMethod: "replace"
                    })
                }}
            ><Translate id={'user.logout'} /></button>
        </div>
    }

    return <button
        key={'loginButton'}
        className={styles.loginPromptButton}
        onClick={() => {
            auth.signinRedirect({
                redirect_uri: window.location.href
            })
        }}
    ><Translate id={'user.login'} /></button>
}

const Login: React.FC<{ displayUserName?: boolean }> = ({ displayUserName }) => {
    const title = useTranslate('user.login')
    return <div className={styles.loginWrapper}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <ParticleSphere className={styles.particleSphere} />
        <div className={styles.loginContainer}>
            <DartmouthIdent
                horizontal
                color={'#909090'}
            />
            <LoginPrompt
                displayUserName={displayUserName}
            />
        </div>
    </div>
}

export const LoginEvents: React.FC<{}> = () => {
    const auth = useAuth()

    React.useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            alert("Your session is about to expire. Do you want to renew it?")
            void auth.signinSilent()
        });

    }, [auth, auth.events, auth.signinSilent])
    return <></>
}

export {
    Login,
}