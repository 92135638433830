import { ApprovalStatus } from '../../models'

const STATUS_COLOR_MAP: Record<ApprovalStatus, string> = {
    imported:   '#666666',
    reserved:   '#e039f4',
    draft:      '#000000',
    pending:    '#0000ff',
    approved:   '#00cc66',
    rejected:   '#da0303',
    revoked:    '#ff9e00',
}

const statusToColor = (status: ApprovalStatus): string | undefined =>
    STATUS_COLOR_MAP?.[status]

export {
    statusToColor,
}
