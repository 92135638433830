import React from 'react'
import { AuthProvider } from 'react-oidc-context'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { config } from './config'

import { IndexPage } from './pages/IndexPage'
import { RootLayout } from './layouts/RootLayout'
import { FileViewer } from './pages/FileViewer'
import { DataBrowser } from './pages/DataBrowser'

import { AuthorisationContainer } from './containers/AuthorisationContainer'
import { AdminGroup, AllGroups, LabellerGroup, ViewerGroup } from './lib/groups'
import { SWRConfig } from 'swr'
import { ProjectViewer } from './pages/ProjectViewer'
import { ModeContainer } from './containers/ModeContainer'
import { MissingPage } from './pages/MissingPage'
import { I18n } from './containers/I18nContainer'

import translations from './translations'
import { ServiceContainer } from './containers/ServiceContainer'

const region = 'en'

const App: React.FC = () =>
    <I18n
        key={region}
        region={region}
        config={translations}
        title
    >
        <ServiceContainer>
            <AuthProvider
                authority={config.authority}
                client_id={config.clientId}
                redirect_uri={config.redirectUri}
                response_type={config.responseType}
                scope={config.scopes.join(' ')}
                onSigninCallback={() =>
                    window.history.replaceState(
                        {},
                        document.title,
                        window.location.pathname + window.location.search.split("state=")[0]
                    )
                }
            >
                <ModeContainer title={'interface'}>
                    <SWRConfig value={{ provider: () => new Map() }}>
                        <BrowserRouter>
                            <Routes>
                                <Route element={<RootLayout />}>
                                    <Route
                                        element={<AuthorisationContainer anyRole={AllGroups} />}
                                    >
                                        <Route
                                            index
                                            element={<IndexPage />}
                                        />
                                        <Route
                                            path={'/data'}
                                            element={<AuthorisationContainer anyRole={AdminGroup} />}
                                        >
                                            <Route
                                                path={':type/:id?'}
                                                element={<DataBrowser />}
                                            />
                                        </Route>
                                        <Route
                                            element={<AuthorisationContainer anyRole={LabellerGroup} />}
                                            path={'/project'}
                                        >
                                            <Route
                                                index
                                                element={<ProjectViewer />}
                                            />
                                            <Route
                                                element={<AuthorisationContainer anyRole={AdminGroup} />}
                                                path={'admin'}
                                            >
                                                <Route
                                                    path={':reference?/:status?'}
                                                    element={<ProjectViewer mode={'admin'} />}
                                                />
                                            </Route>
                                            <Route
                                                path={'id/:id'}
                                                element={<ProjectViewer />}
                                            />
                                            <Route
                                                path={'reference/:reference/:time?'}
                                                element={<ProjectViewer />}
                                            />
                                        </Route>
                                        <Route
                                            path={'/files'}
                                            element={<AuthorisationContainer anyRole={ViewerGroup} />}
                                        >
                                            <Route
                                                path={':protocol'}
                                                element={<FileViewer />}
                                            />
                                        </Route>
                                        <Route
                                            path={'*'}
                                            element={<MissingPage />}
                                        />
                                    </Route>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </SWRConfig>
                </ModeContainer>
            </AuthProvider>
        </ServiceContainer>
    </I18n>

export default App
