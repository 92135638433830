import { AuthenticationState } from '../containers/AuthorisationContainer'
import { config } from '../config'
import { UserCacheRecord } from '../../models'
import { z } from 'zod'

const validateResponse = <T = any>(message: string = 'Unknown', parser = z.any()) => async (response: any): Promise<T> => {
    try {
        const result = await response.json()
        const body = z.object({
            ok: z.boolean(),
            data: parser.optional(),
            error: z.any(),
        }).parse(result)

        if (!body.ok || !body?.data) {
            throw new Error(body?.error ?? message)
        }
        return result.data
    } catch (e: any) {
        throw new Error(e?.message ?? 'Bad Response')
    }
}

const generateJob = (auth: AuthenticationState, _reference: string, batch_id: string, categories: string[], batch_size: number, assigned_to?: string) =>
    fetch(`${config.apiServer}/helpers/generateJob`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${auth?.user?.user?.access_token ?? 'none'}`
        },
        body: JSON.stringify({
            batch_id,
            categories,
            assigned_to,
            batch_size,
        })
    }).then(validateResponse('Unknown'))

const batchFetcher = (getAuth: () => AuthenticationState) => (status: string) =>
    fetch(`${config.apiServer}/data/batchEx_${status}`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then(validateResponse('Failed to retrieve batches')).then((data) =>
        data?.results)

const userDataFetcher = (getAuth: () => AuthenticationState) => (role: 'user' | 'admin') =>
    role === 'admin'
        ? fetch(`${config.apiServer}/data/userCache`, {
            headers: {
                'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
            }
        }).then((res) => res.json()).then((res) => {
            if (!res.ok || !res?.data) {
                throw new Error(res?.error ?? 'Failed to retrieve users')
            }
            return (res.data?.results as UserCacheRecord[]).filter(({ system }) => !system)
        })
        : []

const batchCategoriesFetcher = (getAuth: () => AuthenticationState) => (batchId: string) =>
    fetch(`${config.apiServer}/data/batchCategoriesEx/${batchId}`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then((res) => res.json()).then((res) => {
        if (!res.ok || !res?.data) {
            throw new Error(res?.error ?? 'Failed to retrieve batch categories')
        }
        return res?.data?.categories ?? []
    })

const batchWorkFetcher = (getAuth: () => AuthenticationState) => () =>
    fetch(`${config.apiServer}/helpers/work/list`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then((res) => res.json()).then((res) => {
        if (!res.ok || !res?.data) {
            throw new Error(res?.error ?? 'Failed to retrieve assigned jobs')
        }
        return res?.data ?? []
    })

export {
    batchCategoriesFetcher,
    batchFetcher,
    batchWorkFetcher,
    generateJob,
    userDataFetcher,
}
