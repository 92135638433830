const allLabels = [
    'none_of_the_above',
    'ammunition',
    'axe',
    'axe_head',
    'assault_rifle',
    'box_cutter',
    'boxcutter',
    'butter_knife',
    'butterfly_knife',
    'butterfly_knife_open',
    'ceramic_knife',
    'cleaver',
    'commercial_detonator',
    'curved_knife',
    'cut_throat_razor',
    'cut_throat_razor_open',
    'daggers_martial_arts',
    'detonator',
    'detonator_commercial',
    'folding_knife',
    'folding_knife_open',
    'glasses',
    'grenade',
    'grenade_fuze',
    'hunting_knife',
    'ice_pick',
    'ice_pickaxe',
    'kitchen_knife',
    'knife',
    'letter_opener',
    'machete',
    'metal_bar',
    'mislabeled',
    'no_threat',
    'not_for_training',
    'opinel_knife',
    'phone',
    'pickaxe',
    'pistol',
    'pistol_component_barrel',
    'pistol_component_magazine',
    'pistol_plastic_handle',
    'plastic_pistol',
    'plastic_rifle_magazine',
    'razor',
    'removed',
    'retractable_knife',
    'revolver',
    'revolver_component_base',
    'revolver_cylinder',
    'revolver_magazine',
    'rifle',
    'rifle_component',
    'rifle_component_barrel',
    'rifle_component_body',
    'rifle_component_magazine',
    'rifle_component_stock',
    'rifle_magazine',
    'safety_scissors',
    'samurai_sword',
    'scalpel',
    'scissors',
    'scissors_open',
    'screwdriver',
    'serrated_knife',
    'sheathed_knife',
    'shotgun',
    'spanner',
    'stanely_knife',
    'stanely_knife_closed',
    'sword',
    'tactical_axe',
    'throwing_star',
    'tool',
    'torch',
    'toy_pistol',
    'uncluttered_ice_pickaxe',
    'uncluttered_rifle',
    'watch',
    'wood_splitter',
]

export {
    allLabels,
}
