import { FC, PropsWithChildren } from "react"
import { Box } from "./Box"
import styles from './LoadingPane.module.css'

interface LoadingPaneProps {
    visible?: boolean
    fullscreen?: boolean
    onClickOut?: () => void
}

const LoadingPane: FC<PropsWithChildren<LoadingPaneProps>> = ({ children, onClickOut, fullscreen, visible }) =>
    <div
        key={'fullLayout'}
        onClick={onClickOut && (()=>
            onClickOut())}
        className={[
            fullscreen
                ? styles.fullLayoutFullscreen
                : '',
            visible
                ? styles.fullLayout
                : styles.fullLayoutHidden].join(' ')}
    >
        <Box key={'scanView'} fill flex center>
            <div onClick={(e)=>{
                e.stopPropagation()
            }} className={styles.paneBody}>
                {children}
            </div>
        </Box>
    </div >

export {
    LoadingPane,
}