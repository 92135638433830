const isBrowserChrome = (): boolean => {
    // please note, 
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    //@ts-ignore
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    //@ts-ignore
    var isOpera = typeof window.opr !== "undefined";
    var isFirefox = winNav.userAgent.indexOf("Firefox") > -1;
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    //@ts-ignore
    var isGoogleChrome = (typeof winNav.userAgentData !== "undefined") ? winNav.userAgentData.brands[0].brand === "Google Chrome" : vendorName === "Google Inc.";

    if (isIOSChrome) {
        return true
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false &&
        isGoogleChrome
    ) {
        return true
    }
    return false
}

export {
    isBrowserChrome,
}
