import { FC, useMemo, useState } from 'react'
import styles from './ThreatItems.module.css'
import { BsCheckCircleFill, BsFillQuestionSquareFill, BsPatchExclamationFill, BsPatchQuestionFill } from 'react-icons/bs'
import { useFile } from '../containers/FileContainer'
import { ThreatItem } from '../models/ScanResult'
import useSWR from 'swr'
import { AuthenticationState, useAuthentication } from '../containers/AuthorisationContainer'
import { config } from '../config'
import { ApprovalStatus } from '../../models'
import { statusToColor } from '../lib/status'

export const convertBlobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
        resolve(reader.result as string)
    }
    reader.readAsDataURL(blob)
})

export const extractPath = (uri: string) => {
    const [[, bucket, path, filename]] = uri?.matchAll(/s3:\/\/([^/]+)\/(.+)\/([^/]+)$/g)
    return {
        bucket,
        path,
        filename,
    }
}

const imageFetcher = (getAuth: () => AuthenticationState) => async (imageUri: string): Promise<string> => {
    const { bucket, path, filename } = extractPath(imageUri)
    return fetch(`${config.apiServer}/stores/${bucket}/${encodeURIComponent(`${path}/${filename}`)}/download`, {
        headers: {
            'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
        }
    }).then((result) => result.blob()).then(convertBlobToBase64)
}

interface ApprovalStatusBadgeProps {
    status?: ApprovalStatus

}

const ApprovalStatusBadge: FC<ApprovalStatusBadgeProps> = ({ status }) =>
    status === 'approved'
        ? <BsCheckCircleFill
            title={status}
            style={{
                color: statusToColor(status)
            }}
            className={styles.threatStatusComplete}
        />
        : status === 'pending'
            ? <BsPatchQuestionFill
                title={status}
                style={{
                    color: statusToColor(status)
                }}
                className={styles.threatStatusPending}
            />
            : status === 'draft'
                ? <BsPatchQuestionFill
                    title={status}
                    style={{
                        color: statusToColor(status)
                    }}
                    className={styles.threatStatusDraft}
                />
                : status === 'rejected'
                    ? <BsPatchExclamationFill
                        className={styles.threatStatusRejected}
                        style={{
                            color: statusToColor(status)
                        }}
                        title={status}
                    />
                    : null
interface ThreatItemNotListedProps {
    onSelect?: () => void
    selected?: boolean
}
const ThreatItemNotListed: FC<ThreatItemNotListedProps> = ({ selected, onSelect }) =>
    <div
        className={[
            selected
                ? styles.threatSelected
                : '',
            styles.threatItemImage,
        ].join(' ')}
    >
        <div className={styles.threatItemImagePlaceholder}><BsFillQuestionSquareFill /></div>
        <button onClick={onSelect}>select</button>
    </div>

interface ThreatItemsProp {
    onSelect?: () => void
    onExpand?: () => void
    threat: ThreatItem
    auth: AuthenticationState
    expanded?: boolean
    status?: ApprovalStatus
    selected?: boolean
}

const ThreatItemImage = ({ onSelect, selected, onExpand, threat, auth, status, expanded }: ThreatItemsProp) => {
    const { data, error, isLoading } = useSWR<string>(
        threat.image_uri,
        imageFetcher(()=>auth), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
        refreshInterval: undefined,
    })

    const uri = useMemo(() => {
        return data
    }, [data])

    if (isLoading || error) {
        return null
    }

    return <div
        className={[
            selected
                ? styles.threatSelected
                : '',
            expanded
                ? styles.threatItemImageSelected
                : styles.threatItemImage].join(' ')}
    >
        <ApprovalStatusBadge
            key={'approvalStatus'}
            status={status} />
        <img
            onClick={onExpand}
            src={uri}
            alt={threat.item_id}
        />
        {status === 'reserved'
            ? <button onClick={onSelect}>select</button>
            : null}
        <div className={styles.threatItemCategoryLabel}>
            <div>item: {threat.item_id}</div>
            <div>tray: {threat.tray_id}</div>
        </div>
    </div>
}

interface ThreatItemProp {
    visible: boolean
    autoHide?: boolean
    onSelect?: (threatItemId?: string) => void
    selectedThreatItemId?: string
}

export const ThreatItems = ({ visible, autoHide, onSelect }: ThreatItemProp) => {
    const { context, currentState } = useFile()
    const auth = useAuthentication()
    const [expanded, setExpanded] = useState<string | undefined>()
    const { threats, unknownSelection } = useMemo(() => {
        const threats = context.threats.map((threat) => ({
            ...threat,
            status: context.threatStatus[threat.id],
            selected: ((context.groundTruths
                .find((gt) =>
                    gt.threat_item_id === threat.id)?.id ?? null) === context.selection?.id)
        })).map(({ status, ...threat }) => ({
            ...threat,
            status,
            visible: context.role === 'admin' ||
                ['reserved', 'draft'].includes(status)
        }))

        return {
            threats,
            unknownSelection: context.selection?.id !== undefined && !threats.some((threat) => threat.selected)
        }
    }, [context.role, context.threatStatus, context.threats, context.groundTruths, context.selection])

    return <div
        onClick={() => {
            if (expanded) {
                setExpanded(undefined)
            }
        }}
        className={[
            currentState === 'approvingFile'
                ? styles.threatItemContainerDisabled
                : '',
            expanded || !visible
                ? styles.threatItemContainerSelected
                : styles.threatItemContainer,
            expanded || autoHide
                ? ''
                : styles.threatItemContainerFixed
        ].join(' ')}
    >
        {visible && <div className={styles.threatItemContainerHandle} />}
        {threats
            .filter(({ visible }) =>
                visible)
            .map((threat) =>
                <ThreatItemImage
                    key={`${threat.id}_${threat.selected}`}
                    selected={threat.selected}
                    onSelect={() =>
                        onSelect?.(threat.id)}
                    onExpand={() => {
                        setExpanded((id) =>
                            id === threat.id
                                ? undefined
                                : threat.id)
                    }}
                    expanded={threat.id === expanded}
                    status={threat.status}
                    auth={auth}
                    threat={threat}
                />)}
        <ThreatItemNotListed
            key={`not_listed`}
            selected={unknownSelection}
            onSelect={() =>
                onSelect?.(undefined)}
        />
    </div>
}

