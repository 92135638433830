import React, { useEffect, useMemo, useState } from 'react'
import rosetta, { Rosetta } from 'rosetta'
import clsx from 'clsx'
import { Helmet } from 'react-helmet'


var i18n: Rosetta<any>
var config


const setupI18N = (_config: Record<string, any>, _region: string) => {
    if (i18n === undefined) {
        config = _config
        i18n = rosetta(_config)
        i18n.locale(_region)
    }
    return i18n
}

const setupRegion = (_region: string) => {
    i18n.locale(_region)
}

interface TranslateProps {
    id: string
    data?: any
    region?: string
    className?: string
    wrapper?: boolean
}
const translate = (id: string, data?: any, region?: string): string =>
    i18n.t(id, data, region)

const useTranslate = (id: string, data?: any, region?: string): string =>
    useMemo(() => i18n.t(id, data, region), [id, data, region])

const Translate = ({ id, data, region, className, wrapper = true }: TranslateProps) => {
    const result = useTranslate(id, data, region)

    return wrapper ? <span
        key={id}
        data-i18n-region={region}
        data-i18n-id={id}
        className={clsx('i18n--elem ',className)}
    >
        {result}
    </span> : result
}

interface I18nProps {
    region: string
    config: Record<string, any>
    children: any
    title?: boolean
}

const I18n = ({ region, config, children, title }: I18nProps) => {
    const [context] = useState(setupI18N(config, region))
    useEffect(() => {
        console.debug('[I18n]', 'set region', region)
        setupRegion(region)
    }, [region])

    return context ? <>
            {title ? <Helmet
                defaultTitle={translate('title.root')}
                titleTemplate={translate('title.template')}
            >
                <meta charSet={'utf-8'} />
            </Helmet> : null}
        {children}
    </> : null
}

export {
    I18n,
    Translate,
    useTranslate,
    translate,
}
