import React from 'react'
import { Outlet } from "react-router-dom"

const RootLayout = () => 
    <div
        key={'root'}
        style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
        }}
    >
        <div style={{ flex: 1 }}>
            <Outlet />
        </div>
    </div>

export {
    RootLayout,
}
