import { useEffect } from "react"

const useKeyHandler = (handler: (key: string, event: KeyboardEvent) => void) => {
    useEffect(() => {
        const keypressHandler = function (event: KeyboardEvent) {
            handler(event.key, event)
        }
        document.addEventListener('keydown', keypressHandler)
        return () => {
            document.removeEventListener('keydown', keypressHandler)
        }
    })

    return null
}

export {
    useKeyHandler,
}
