import React from 'react'
import { ModelViewerTools, ModelViewerControls, ModelViewerRenderer, ModelViewerContainer, RenderStyle, ToolbarSpacer, ToolbarGroup, ToolbarIcon } from '@bbai-dartmouth/dartmouth-model-viewer-lib'
import styles from './ScanViewerLayout.module.css'
import { BsExclamationOctagonFill } from 'react-icons/bs'
import { useScanConclusions } from '../lib/useScanConclusions'
import { Helmet } from 'react-helmet'
import { BoundingBoxWizard, DebugBoundingBoxes } from '../components/BoundingBoxWizard'
import { allLabels } from '../lib/labels'
import { useFile } from '../containers/FileContainer'
import { useApplication } from '../containers/FileApplicationContainer'
import { fileToTitle } from '../lib/files'

import { PagePrompt } from '../components/PagePrompt'
import { ThreatItems } from '../components/ThreatItems'
import { ReportFileControls } from '../components/ReportFileControls'
import { LoadingPane } from '../components/LoadingPane'
import { useNavigate } from 'react-router-dom'
import { ApprovalPane } from '../components/ApprovalPane'
import { HasRole } from '../components/HasRole'
import { ViewerGroup } from '../lib/groups'
import { Translate } from '../containers/I18nContainer'


const PageActions = () => {
    const { send: applicationSend } = useApplication()
    const { context, currentState, send } = useFile()
    const nav = useNavigate()

    if (currentState === 'fileUpdateError') {
        return <LoadingPane
            fullscreen={true}
            key={'savingPane'}
            visible
        >
            <div>Failed To Update Scan</div>
            <button onClick={() => send({
                type: 'clearErrors',
            })}><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileSaveError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Save Data</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    } else if (currentState === 'fileLoadError') {
        return <LoadingPane
            fullscreen={false}
            key={'savingPane'}
            visible
        >
            <div>Failed To Load Scan</div>
            <button
                onClick={() => send({
                    type: 'clearErrors',
                })}
            ><Translate id={'buttons.close'} wrapper={false} /></button>
        </LoadingPane>
    }
    else if (currentState === 'approvingFile') {
        return <ApprovalPane />
    }
    else if (currentState === 'completedFile') {
        if (context.nextFile === undefined) {
            return <LoadingPane
                fullscreen={true}
                key={'completePane'}
                visible
                onClickOut={() => {
                    if (context.role === 'admin') {
                        send({
                            type: 'closeOverlay',
                        })
                    }
                }}
            >
                <div>Batch Complete</div>
                <button
                    onClick={() => {
                        nav('/')
                    }}
                ><Translate id={'buttons.close'} wrapper={false} /></button>
                <button
                    onClick={() => {
                    }}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </LoadingPane>
        }

        return <LoadingPane
            key={'completePane'}
            visible
            onClickOut={() => {
                if (context.role === 'admin') {
                    send({
                        type: 'closeOverlay',
                    })
                }
            }}
        >
            <div>Scan Complete</div>
            {context.nextFile !== undefined && <button
                onClick={() => applicationSend({
                    type: 'selectFile',
                    file: context.nextFile!,
                })}
            ><Translate id={'buttons.next'} wrapper={false} /></button>}
            <HasRole any={ViewerGroup}>
                <button
                    onClick={() => applicationSend({
                        type: 'resetFile',
                    })}
                ><Translate id={'buttons.reset'} wrapper={false} /></button>
            </HasRole>
        </LoadingPane>
    }

    return <>
        <LoadingPane
            fullscreen={true}
            key={'savingPane'}
            visible={currentState === 'savingFile'}
        >Saving...</LoadingPane>
        <LoadingPane
            fullscreen={true}
            key={'updatingPane'}
            visible={currentState === 'updatingFile'}
        >Updating...</LoadingPane>
    </>

}


const ScanViewerLayout: React.FC<{ debug?: boolean, viewRef?: React.LegacyRef<HTMLCanvasElement> }> = ({ debug, viewRef }) => {
    const { context, send, currentState } = useFile()
    const { context: applicationContext, send: applicationSend, currentState: applicationCurrentState } = useApplication()
    const { conclusions, customConclusions, selectedConclusion } = useScanConclusions(context?.results?.conclusions ?? [], context.customConclusions, context, context.selection)

    return <div
        key={'scanViewContent'}
        className={styles.scanViewContent}
    >
        <Helmet>
            <title>{fileToTitle(context.protocol, context.file!, context?.store ?? context.basePath)}</title>
        </Helmet>
        <ModelViewerContainer
            key={'modelContain'}
            id={context.scanId!}
            debug={debug}
            meta={{
                file: context.file!.path,
                path: context.path!,
            }}
            model={context.data!}
            selection={(currentState === 'editingFile' ? undefined : context.selection) ?? {}}
            group={[]}
            onLock={() => send({
                type: 'enterLockMode'
            })}
            onUnlock={() => send({
                type: 'exitEditMode'
            })}
        >
            <PageActions
                key={'pageActions'}
            />
            {currentState === 'viewingFile'
                ? <PagePrompt
                    visible={customConclusions.length > 0}
                    message={'Save'}
                    onClick={() => send({
                        type: 'saveData',
                        mode: 'conclusions',
                    })}
                />
                : null}
            {applicationContext.mode === 'project'
                ? <ThreatItems
                    autoHide={context.selection?.id === undefined && currentState !== 'approvingFile'}
                    visible={['viewingFile', 'completedFile', 'approvingFile'].includes(currentState)}
                    onSelect={(threatItemId) => send({
                        type: 'setThreatItem',
                        threat: context?.threats.find((threat) =>
                            threat.id === threatItemId),
                    })}
                    selectedThreatItemId={context.selectedThreat?.id}
                />
                : null}
            <ReportFileControls
                visible={['viewingFile', 'completedFile', 'approvingFile'].includes(currentState)}
                key={'reportControls'}
                threatCount={context.mode === 'file'
                    ? context.results?.conclusions.length
                    : context?.threats?.length}
                customConclusions={customConclusions ?? []}
                selection={selectedConclusion}
            />
            <ModelViewerTools
                options={{
                    renderStyles: [RenderStyle.Iso, RenderStyle.Mip],
                }}
                key={'mvTools'}
            />
            <ModelViewerRenderer
                key={'mvPane'}
                controls
                onUpdateConclusion={(id, scanId, data) =>
                    send({
                        type: 'updateConclusion',
                        id,
                        scanId,
                        data,
                    })}
                onPointerMissed={selectedConclusion ? (() => send({
                    type: 'setSelection'
                }))
                    : undefined}
                onSelectConclusion={(id, scanId, mode) => {
                    if (mode === 0) {
                        return send({
                            type: 'setSelection',
                            id,
                            scanId,
                        })
                    } else if (mode === 1) {
                        return send({
                            type: 'removeCustomConclusion',
                            id,
                            scanId,
                        })
                    }
                }}
                conclusions={conclusions ?? []}
                customConclusions={customConclusions}
                style={{
                    position: 'absolute',
                    inset: 0,
                    userSelect: 'none',
                }}
                canvasRef={viewRef}
                config={{
                    colors: {
                        boundingBox: '#f00',
                        customBoundingBox: '#000',
                    },
                    labelList: allLabels,
                    editable: true,
                }}
            >
                {currentState !== 'editingFile'
                    ? <DebugBoundingBoxes
                        scanId={context.scanId!}
                        color={'#f0f'}
                        groundTruths={context.groundTruths}
                        conclusions={debug
                            ? customConclusions
                            : []}
                        selection={context.selection}
                        onUpdate={(id, scanId, data) =>
                            send({
                                type: 'updateConclusion',
                                id,
                                scanId,
                                data,
                            })}
                        onSelect={(id, scanId, mode) => {
                            if (mode === 0) {
                                return send({
                                    type: 'setSelection',
                                    id,
                                    scanId,
                                })
                            } else if (mode === 1) {
                                return send({
                                    type: 'removeCustomConclusion',
                                    id,
                                    scanId,
                                })
                            }
                        }}
                    /> : null}
                {currentState === 'editingFile'
                    ? <BoundingBoxWizard
                        key={'boundingWizard'}
                        category={context.selectedThreat?.item_id}
                        onCancel={() => {
                            send({
                                type: 'toggleEditMode'
                            })
                        }}
                        onComplete={({ id, label, userLabel, boundingBox, globalBoundingBox, orientation }) =>
                            send({
                                type: 'createCustomConclusion',
                                data: {
                                    id,
                                    scanId: context.scanId!,
                                    threatItemId: context.selectedThreat?.id ?? null,
                                    boundingBox,
                                    globalBoundingBox,
                                    probability: 1,
                                    label: label,
                                    category: userLabel,
                                    isBelowThreshold: false,
                                    inferences2D: [],
                                    runtimeThreshold: 0,
                                    custom: true,
                                    orientation,
                                }
                            })
                        }
                    /> : null}
            </ModelViewerRenderer>
            {currentState !== 'completedFile'
                ? <ModelViewerControls
                    autoHide={false}
                    key={'mvControls'}
                    fullscreen={applicationContext.view.fullscreen}
                    toggleFullscreen={() => applicationSend({
                        type: 'toggleFullscreen'
                    })}
                >
                    <ToolbarSpacer />
                    <ToolbarGroup>
                        <ToolbarIcon
                            element={BsExclamationOctagonFill}
                            label={'Add ground truth'}
                            onClick={() => send({
                                type: 'toggleEditMode'
                            })}
                        />
                    </ToolbarGroup>
                </ModelViewerControls>
                : null}
        </ModelViewerContainer>
    </div>
}

export {
    ScanViewerLayout,
}
