import React, { FC, useMemo, useState } from 'react'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import styles from './DataBrowser.module.css'
import { AuthenticationState, useAuthentication } from '../containers/AuthorisationContainer'
import { config } from '../config'

const MatchUUID = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'

const SearchFilterInput = ({ schema, setFilter, filter }: any) => {
    if (schema?.format === 'uuid') {
        return <input
            placeholder={'uuid'}
            pattern={MatchUUID}
            onChange={({ target }) => {
                if (target.validity.valid && target.value.length > 0) {
                    setFilter(target.value)
                } else if (filter !== undefined) {
                    setFilter(undefined)
                }
            }}
        />
    } else if (schema?.format === 'uri') {
        return <input
            type={'url'}
            placeholder={'uri'}
            onChange={({ target }) => {
                if (target.validity.valid && target.value.length > 0) {
                    setFilter(target.value)
                } else if (filter !== undefined) {
                    setFilter(undefined)
                }
            }}
        />
    } else if (schema?.format === 'date-time') {
        return <input
            type={'date'}
            value={filter}
            onChange={({ target }) =>
                setFilter(target.value)}
        />
    } else if (schema?.enum !== undefined || (schema?.type === 'array' && schema?.items?.format !== 'uuid') || schema?.type === 'boolean') {
        const options = schema?.enum ?? schema?.items?.enum ?? [true, false]

        return <select value={filter} onChange={({ target }) => setFilter(target.value === '-' ? undefined : schema?.type === 'boolean' ? Boolean(target.value === 'true') : target.value)}>
            <option key={'reset'} value={'-'}>-</option>
            {options.map((opt: any) => <option key={opt} value={opt}>{schema?.type === 'boolean'
                ? (opt === true
                    ? 'true'
                    : 'false')
                : opt}</option>)}
        </select>
    } else if (schema?.type === 'boolean') {
        return <input type={'checkbox'} value={filter} onChange={({ target }) => setFilter(target.checked)} />
    }
    return <input
        type={'text'}
        placeholder={schema?.format}
        onChange={({ target }) => {
            if (String(target.value).trim().length > 0) {
                setFilter(target.value)
            } else if (filter !== undefined) {
                setFilter(undefined)
            }
        }}
    />
}

const SearchFilter = ({ schema, property, setFilter, filter }: any) => {
    const propertySchema = useMemo(() =>
        schema.properties[property]
        , [schema, property])


    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label
            style={{
                textTransform: 'uppercase',
                fontWeight: 200,
                fontSize: '0.6em',
            }}
        >{propertySchema?.description ?? 'unknown'}</label>
        <SearchFilterInput
            schema={propertySchema}
            filter={filter}
            setFilter={setFilter}
        />
    </div>
}

const DataRecord = ({ schema, data, type }: any) => {
    return <tr>
        {Object.keys(schema.properties)
            .filter((property) =>
                schema.properties?.[property]?.description !== undefined && schema.properties?.[property]?.type !== 'number')
            .map((property) =>
                <td
                    className={[
                        styles.dataRecordRow,
                        styles[`dataRecordRow--property__${property}`],
                        styles[`dataRecordRow--format__${schema.properties?.[property]?.format ?? 'any'}`],
                        styles[`dataRecordRow--type__${schema.properties?.[property]?.enum ? 'enum' : 'identity'}`],
                        styles[`dataRecordRow--type__${schema.properties?.[property]?.type ?? 'any'}`],
                    ].join(' ')}
                    key={property}
                >{schema.properties?.[property].type === 'boolean' ? String(data[property]) : schema.properties?.[property].format === 'date-time' ? data[property].split('T')?.[0] : data[property] ?? '-'}</td>)}
    </tr>
}

const DataHeader = ({ schema, type, setFilters }: any) => {
    return <tr>
        {Object.keys(schema.properties)
            .filter((property) =>
                schema.properties?.[property]?.description !== undefined && schema.properties?.[property]?.type !== 'number')
            .map((property) =>
                <th key={property} onClick={() => setFilters((filters: any) => ({
                    ...filters,
                    dir: (filters?.dir &&
                        filters.dir !== 'desc')
                        ? 'desc' : 'asc',
                    ord: property,
                }))}>{schema.properties?.[property].description}</th>)}
    </tr>
}
const cachefetcher = (getAuth: () => AuthenticationState) => ([query, type]: string[]) => fetch(`${config.apiServer}/data/${type}?${query}`, {
    headers: {
        'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
    }
}).then((res) => res.json())
interface DataFooterProps {
    data: { size: number, total: number, page: number }
    setFilters: (h: (v: any) => any | any) => void
}
const DataFooter = ({ data: { total, size, page }, setFilters }: DataFooterProps) => {
    const pageCount = useMemo(() =>
        Math.ceil(total / size)
        , [total, size])
    return <div className={styles.dataFooter}>
        {Array(pageCount).fill(null).map((_, i) =>
            <div
                key={`page_${i}`}
                className={(i + 1) === page ? styles.dataFooterPageSelectorActive : styles.dataFooterPageSelector}
                onClick={() => {
                    setFilters((current) => ({
                        ...current,
                        page: i + 1,
                    }))
                }}>{i + 1}</div>)}
    </div>
}

interface DataQueryProps {
    schema: any
    filters: Record<string, any>
    type: string
    setFilters: (h: (v: any) => any | any) => void
}

const DataQuery = ({ schema, filters, type, setFilters }: DataQueryProps) => {
    const auth = useAuthentication()
    const searchQuery = useMemo(() =>
        new URLSearchParams(filters).toString()
        , [filters])
    const { data: response, error, isLoading } = useSWR([searchQuery, type],
        cachefetcher(()=>auth), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    const cached = useMemo(() => {
        return response !== undefined
            ? response
            : undefined
    }, [response])

    if (!cached?.data || isLoading) {
        return <div>loading...</div>
    }
    if (error || response?.error) {
        return <div>{JSON.stringify(error ?? response?.error)}</div>
    }

    return <>
        {isLoading && <div style={{
            position: 'absolute',
            top: '1em',
            right: '1em',
        }}>loading...</div>}
        <table
            style={{
                fontSize: '0.8em',
            }}
            className={'searchResults'}>
            <thead>
                <DataHeader type={type} schema={schema} setFilters={setFilters} />
            </thead>
            <tbody>
                {cached?.data?.results?.map((record: any) =>
                    <DataRecord key={record.id} type={type} data={record} schema={schema} />)}
            </tbody>
        </table>
        <DataFooter setFilters={setFilters} data={cached?.data} />
    </>
}

const DataFilters = ({ schema, type, filters, setFilters }: any) => {
    return <div
        className={styles.searchFilters}
    >
        {Object.keys(schema.properties)
            .filter((property) =>
                schema.properties?.[property]?.description !== undefined && schema.properties?.[property]?.type !== 'number')
            .map((property) =>
                <div
                    key={property}
                >
                    <SearchFilter
                        filter={filters?.[property] ?? ''}
                        setFilter={(v: any) => setFilters((current: any) => ({
                            ...current,
                            [property]: v,
                        }))}
                        schema={schema} property={property} />
                </div>)}
    </div>
}

const fetcher = (getAuth: () => AuthenticationState) => (url: string) => fetch(url, {
    headers: {
        'authorization': `Bearer ${getAuth()?.user?.user?.access_token ?? 'none'}`
    }
}).then((res) => res.json())

const DataBrowser: FC = () => {
    const auth = useAuthentication()
    const params = useParams()
    const baseType = useMemo(() =>
        params.type ?? 'foo', [params])
    const [filters, setFilters] = useState<Record<string, any>>(params?.id ? {
        id: params?.id,        
    } : {

    })
    const searchFilters = useMemo(() =>
        Object.keys(filters).reduce((acc, filter) => {
            if (filters?.[filter] === undefined || filters[filter] === '') {
                return acc
            }
            return {
                ...acc,
                [filter]: filters[filter],
            }
        }, {})
        , [filters])

    const { data, error, isLoading } = useSWR(
        `${config.apiServer}/data/${baseType}/schema`,
        fetcher(()=>auth))

    if (!data && isLoading) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>{JSON.stringify(error)}</div>
    }

    return <div
        key={'dataBrowser'}
        className={styles.dataBrowserPage}
    >
        <h2
            style={{
                margin: 0,
                padding: 0,
                fontWeight: 200,
                fontSize: '2em',
                textTransform: 'uppercase',
            }}
        >{baseType} data</h2>
        <DataFilters
            key={'dataFilters'}
            type={baseType}
            schema={data?.data}
            setFilters={setFilters}
            filters={filters}
        />
        <DataQuery
            key={'dataQuery'}
            filters={searchFilters}
            setFilters={setFilters}
            type={baseType}
            schema={data.data}
        />
    </div>
}

export {
    DataBrowser,
}
