import React from 'react'

const Box: React.FC<React.PropsWithChildren<{ flex?: boolean, fill?: boolean, center?: boolean }>> = ({ children, flex, fill, center }) => {
    const style = React.useMemo(()=>{
        let newStyle = {
            userSelect: 'none' as any
        }
        if (flex || center) {
            newStyle = Object.assign(newStyle, {
                display: 'flex',
            })
        }
        if (center) {
            newStyle = Object.assign(newStyle, {
                alignItems: 'center',
                justifyContent: 'center',
            })
        }
        if (fill) {
            newStyle = Object.assign(newStyle, {
                flexGrow: 2,
                height: '100%',
                width: '100%',
                fontSize: '2em',
                fontWeight: 200,
            })
        }
        return newStyle
    }, [fill, center, flex])

    return <div style={style}>{children}</div>
}

export {
    Box,
}
