import styles from './ProgressBar.module.css'
import { ProgressRefs } from '../machines/layer/loaders'

type ProgressBarProps = {
    visible?: boolean
} & ProgressRefs

const ProgressBar: React.FC<ProgressBarProps> = ({ progressRef, infoRef, labelRef, visible }) => {
    return <div
        className={visible
            ? [styles.progressBarContainer, styles.progressBarContainerVisible].join(' ')
            : styles.progressBarContainer}
    >
        <div ref={infoRef} className={styles.progressBarInfoLabel}>
            <label>&nbsp;</label>
            <label>&nbsp;</label>
        </div>
        <progress className={styles.progressBarElement} ref={progressRef} />
        <div ref={labelRef} className={styles.progressBarStatusLabel}>
            <label>&nbsp;</label>
            <label>&nbsp;</label>
        </div>
    </div>
}

export {
    ProgressBar,
}
