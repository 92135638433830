import React, { useMemo } from 'react'
import { BsFolderSymlinkFill, BsFolder, BsFileEarmarkBinaryFill, BsFileZipFill } from 'react-icons/bs'
import type { IconType } from 'react-icons'
import prettyBytes from 'pretty-bytes'

import styles from './TreeView.module.css'
import { FileResult } from '../types'
import { shortFilename } from '../lib/files'


export interface SelectedFolder {
    tree: FileResult[],
    folderPath: string
}

interface TreeViewItemInfoProps {
    selected?: boolean
    file?: FileResult
}

const TreeViewItemInfo = ({ selected, file }: TreeViewItemInfoProps) =>
    selected
        ? <span className={styles.treeViewItemNote}>viewing</span>
        : <span className={styles.treeViewItemNote}>{(file?.type === 'file' && prettyBytes(file.size)) || (file?.type === 'folder' && `${file?.children} ${file?.children > 1 ? 'files' : 'file'}`)}</span>


interface TreeViewItemProps {
    file?: FileResult
    label?: string
    onClick: () => void
    icon: IconType
    selected?: boolean
}

const TreeViewItem = ({ selected, file, label, onClick, icon: IconElement }: TreeViewItemProps) =>
    <span
        className={selected
            ? styles.treeViewItemContainerSelected
            : styles.treeViewItemContainer}
        onClick={onClick}
    >
        <div className={styles.treeViewItemIcon}>
            <IconElement className={styles.treeViewItemIconSvg} />
        </div>
        <div className={styles.treeViewItemLabel} title={file?.ref}>
            {label ?? shortFilename(file?.name)}
        </div>
        <TreeViewItemInfo selected={selected} file={file} />
    </span>


const getParentPath = (directory: string): string => {
    let parentPath = directory.endsWith('/')
        ? directory.replace(/[^/]+\/$/, '')
        : directory.replace(/\/[^/]+$/, '')
    if (parentPath.length < 1) {
        parentPath = '/'
    }
    return parentPath
}
interface TreeViewProps {
    tree: FileResult[]
    onFileSelect: (file: FileResult) => void
    file?: FileResult
    selectedFolderPath: string
    basePath?: string
    setSelectedFolderPath: (path: string) => void
}

const TreeView: React.FC<TreeViewProps> = ({ tree, onFileSelect, file, basePath, selectedFolderPath, setSelectedFolderPath }) => {
    const currentTree = useMemo(() => {
        const currentPath = selectedFolderPath.replace(basePath!, '/').replace(/^\/\//, '/')
        return tree.filter((node) =>
            node.parent === currentPath && node.visible)
    }, [selectedFolderPath, basePath, tree])

    return <div className={styles.treeViewContainer}>
        {(selectedFolderPath !== basePath && selectedFolderPath !== '/')
            && <TreeViewItem
                key={'parentNode'}
                label={'..'}
                icon={BsFolderSymlinkFill}
                onClick={() => {
                    try {
                        setSelectedFolderPath(getParentPath(selectedFolderPath))
                    } catch (e: any) {
                        console.error('failure browsing to parent folder', e)
                    }
                }}
            />}
        {currentTree.map((node, i) =>
            node.type === 'folder'
                ? <TreeViewItem
                    //@ts-ignore
                    key={node.id || i}
                    file={node}
                    icon={BsFolder}
                    onClick={() => {
                        setSelectedFolderPath(node.path)
                    }}
                />
                : <TreeViewItem
                    //@ts-ignore
                    key={node.id ?? i}
                    file={node}
                    icon={/\.br$/.test(node.path)
                        ? BsFileZipFill
                        : BsFileEarmarkBinaryFill}
                    selected={node.path === file?.path}
                    onClick={() =>
                        node.path && onFileSelect(node)}
                />)}
    </div>
}

export {
    TreeView,
}
