import React, { FC, useEffect, useState } from 'react'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import styles from './tools/ToolPage.module.css'
import { AuthenticationState, useAuthentication } from '../containers/AuthorisationContainer'
import Select from 'react-select'
import { config } from '../config'
import { ToolPage, ToolPageBody, ToolPageBodySection, ToolPageHeader, ToolPageHeaderButtons } from './tools/ToolPage'
import { translate, Translate } from '../containers/I18nContainer'
import { useApplication } from '../containers/FileApplicationContainer'
import { UserCacheRecord } from '../../models'
import { batchCategoriesFetcher, batchFetcher, batchWorkFetcher, generateJob, userDataFetcher } from '../fetchers/jobs'


interface JobBuilderFormProps {
    auth: AuthenticationState
    reference: string
    assignedUserId?: string
    batchId: string
    onGenerate: (reference: string, batchId: string, categories: string[], batchSize: number, assignedUserId?: string) => Promise<void>
}

const batchSizes = [
    {
        label: 'all',
        value: 0,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '10',
        value: 10,
    },
    {
        label: '1',
        value: 1,
    },
]

const JobBuilderForm: FC<JobBuilderFormProps> = ({ auth, reference, batchId, assignedUserId, onGenerate }) => {
    const { data: allCategories, error, isLoading } = useSWR(batchId, batchCategoriesFetcher(() => auth))
    const [categories, setCategories] = useState<string[]>([])
    const [batchSize, setBatchSize] = useState<number>(0)

    if (!allCategories && isLoading) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>No scans found...</div>
    }

    return <div
        className={styles.jobBuilderRow}
    >
        <div className={styles.builderLabel}>Select your preferred categories</div>
        <Select
            onChange={(v: any) => {
                setCategories(v.map(({ value }: any) =>
                    value))
            }}
            options={allCategories.map((label: string) => ({
                value: label,
                label,
            }))}
            isMulti
        />
        <div className={styles.builderLabel}>Select the job size</div>
        <Select
            defaultValue={{
                label: 'all',
                value: 0,
            }}
            onChange={(v: any) => {
                setBatchSize(v.value)
            }}
            options={batchSizes.map(({ value, label }: any) => ({
                value,
                label,
            }))}
        />

        {categories.length > 0
            ? <button
                onClick={({ currentTarget }) => {
                    if (currentTarget.disabled) {
                        return
                    }
                    currentTarget.disabled = true
                    onGenerate(reference, batchId, categories, batchSize, assignedUserId).finally(() => {
                        currentTarget.disabled = false
                    })
                }}
                className={styles.buttonGenerate}
            >
                <Translate
                    id={'buttons.generateJob'}
                    wrapper={false}
                />
            </button>
            : null}
    </div>
}

interface JobBuilderProps {
    users: UserCacheRecord[]
    auth: AuthenticationState
    role: 'user' | 'admin'
    onGenerate: (reference: string, batchId: string, categories: string[], batchSize: number, assignedUserId?: string) => Promise<void>
}

const JobBuilder: FC<JobBuilderProps> = ({ onGenerate, role, users }) => {
    const auth = useAuthentication()
    const [batch, setBatchId] = useState<{ value: string, label: string } | undefined>()
    const [assignedUserId, setAssignedUserId] = useState<{ value: string, label: string } | undefined>({ value: auth.profile.sub, label: translate('format.user.label', { name: auth.profile.name, email: auth.profile.email }) })
    const { data: batchData, error, isLoading } = useSWR('imported', batchFetcher(() => auth))

    if (!batchData && isLoading) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>No scans found...</div>
    }

    return <div
        className={styles.jobBuilderRow}
    >
        {role === 'admin'
            ? <>
                <div className={styles.builderLabel}>Assign to user</div>
                <Select
                    defaultValue={assignedUserId}
                    onChange={(v: any) => {
                        setAssignedUserId(v)
                    }}
                    options={users.map((user) => ({
                        value: user.id,
                        label: translate('format.user.label', user),
                    }))}
                />
            </>
            : null}
        <div className={styles.builderLabel}>Select a batch</div>
        <Select
            onChange={(v: any) => {
                setBatchId(v)
            }}
            options={batchData.map((batch: any) => ({
                value: batch.id,
                label: batch.reference,
            }))}
        />
        {batch && (assignedUserId || role === 'user') &&
            <JobBuilderForm
                auth={auth}
                onGenerate={onGenerate}
                assignedUserId={role === 'admin' ? assignedUserId!.value : undefined}
                batchId={batch.value}
                reference={batch.label}
            />}
    </div>
}

interface JobBuilderPageProps {
    role: 'user' | 'admin'
    toggleMode: () => void
}

const JobBuilderPage: FC<JobBuilderPageProps> = ({ role, toggleMode }) => {
    const nav = useNavigate()
    const auth = useAuthentication()
    const { data: userData, error, isLoading } = useSWR(role, userDataFetcher(() => auth))
    const [generateError, setGenerateError] = useState<string | undefined>()

    if (!userData && isLoading) {
        return <div>loading...</div>
    }
    if (error) {
        return <div>No users found...</div>
    }


    return <div>
        {generateError &&
            <div className={styles.errorGenerating}>Failed to generate job: {generateError}...</div>}
        <JobBuilder
            role={role}
            users={userData ?? []}
            auth={auth}
            onGenerate={async (reference, batch_id, categories, batch_size, assigned_to) => {
                setGenerateError(undefined)
                return generateJob(auth, reference, batch_id, categories, batch_size, assigned_to)
                    .then(() => {
                        if (role === 'admin' && assigned_to !== auth.profile.sub) {
                            toggleMode()
                            return
                        }
                        nav(`/project/reference/${reference}`)
                    })
                    .catch((e: any) => {
                        setGenerateError(e?.message ?? 'Unknown error')
                    })
            }}
        />
    </div>
}
interface JobListingPageProps {
    toggleMode: () => void
}

const JobListingPage: FC<JobListingPageProps> = ({ toggleMode }) => {
    const auth = useAuthentication()
    const { data: workData, error, isLoading } = useSWR('reserved', batchWorkFetcher(() => auth))


    if (isLoading) {
        return <div>loading...</div>
    }
    if (error || !workData) {
        return <div>Error loading work</div>
    }

    return <ToolPageBodySection empty={workData?.length < 1}>
        <h3
            className={styles.sectionHeader}
            style={{
                borderBottomColor: '#000'
            }}
        >Your Work</h3>
        <table className={styles.jobListTable}>
            <thead>
                <tr>
                    <th>reference</th>
                    <th>batch id</th>
                    <th>categories</th>
                    <th>scans</th>
                </tr>
            </thead>
            <tbody>
                {workData?.map((batch: any) =>
                    <tr key={batch.batch_id}>
                        <td className={styles.jobListCellReference}><a href={`/project/reference/${batch.reference}`}>{batch.reference}</a></td>
                        <td className={styles.jobListCellBatchId}>{batch.batch_id}</td>
                        <td className={styles.jobListCellCategory}>{batch.categories.join(', ')}</td>
                        <td className={styles.jobListCellCount}>{batch.count}</td>
                    </tr>)}
            </tbody>
        </table>
    </ToolPageBodySection>
}


const JobPage: FC = () => {
    const { context: { admin } } = useApplication()
    const [newJob, setNewJob] = useState<boolean>(false)
    const nav = useNavigate()
    const title = newJob
        ? 'create'
        : 'list'

    useEffect(() => {
        nav('/project', { replace: false })
    }, [])

    return <ToolPage title={'jobs'} key={'adminPage'}>
        <ToolPageHeader>
            <h2>jobs | <span>{title}</span></h2>
            <ToolPageHeaderButtons>
                <button
                    onClick={() => setNewJob((nj) => !nj)}
                >{newJob ? 'view jobs' : 'create job'}</button>
            </ToolPageHeaderButtons>
        </ToolPageHeader>
        <ToolPageBody>
            {newJob
                ? <JobBuilderPage key={'buildJob'} role={admin ? 'admin' : 'user'} toggleMode={() => setNewJob((nj) => !nj)} />
                : <JobListingPage key={'buildJob'} toggleMode={() => setNewJob((nj) => !nj)} />}
        </ToolPageBody>
    </ToolPage>
}

export {
    JobPage,
}
