import { CSSProperties, FC, PropsWithChildren, useEffect, useState } from "react"
import { useApplication } from "../containers/FileApplicationContainer"
import { Modal } from "./Modal"
import Select from 'react-select'

interface ButtonProps {
    onClick?: () => void
}

const SectionButton: FC<PropsWithChildren<ButtonProps>> = ({ children, onClick }) =>
    <button
        style={{
            fontSize: '1em',
            fontWeight: 200,
            border: 'none',
            padding: '0.5em',
            textTransform: 'uppercase',
            borderRadius: '0.2em',
        }}
        onClick={onClick}
    > {children}</button >

const SectionTitle: FC<PropsWithChildren> = ({ children }) =>
    <div style={{
        fontSize: '1.5em',
        fontWeight: 200,
    }}>{children}</div>

interface SectionProps {
    maxHeight?: string
    style?: CSSProperties
}

const Section: FC<PropsWithChildren<SectionProps>> = ({ children, style }) =>
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5em',
        ...style,
    }}>{children}</div>

const RemoteFolderSelect: React.FC = () => {
    const { context, send } = useApplication()

    const [pathName, setPathName] = useState('')

    return <Modal>
        <Section style={{
            maxHeight: '80vh',
            marginLeft: '-1.5em',
            paddingLeft: '1.5em',
            marginRight: '-1.5em',
            paddingRight: '1.5em',
            fontFamily: 'monospace',
        }}>
            <SectionTitle>Enter Remote Path</SectionTitle>
            {context.projects.length === 1 && context.projects[0].label === 'root'
                ? <>
                    <input
                        autoFocus
                        autoComplete={'dartmouth--store--name'}
                        type={'text'}
                        value={pathName}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                send({
                                    type: 'selectProject',
                                    id: pathName.replace(/\/$/, ''),
                                })
                            }
                        }}
                        onChange={({ target }) =>
                            setPathName(String(target.value).replace(/^\//, ''))}
                    />
                    <SectionButton onClick={() => send({
                        type: 'selectProject',
                        id: pathName.replace(/\/$/, ''),
                    })}>select</SectionButton>
                </>
                : context.projects.map((project) =>
                    <div
                        key={project.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            gap: '2em',
                        }}
                    >
                        <div style={{ flexGrow: 2 }}>{project.id.slice(0, -project.label.length)}<b>{project.label}</b></div>
                        <SectionButton onClick={() => send({
                            type: 'selectProject',
                            id: project.id,
                        })}>continue</SectionButton>
                    </div>)}
        </Section>
    </Modal>
}

const RemoteStoreSelect: React.FC = () => {
    const { context, send } = useApplication()
    const [tempVal, setTempVal] = useState<{ value: string } | null>((context?.store && {value: context?.store}) || null)

    useEffect(()=>{
        console.log('selection change', tempVal)
    },[tempVal])

    return <Modal>
        <Section>
            <SectionTitle>Choose Remote Store</SectionTitle>
            <Select
                isSearchable={true}
                name={'Select Bucket'}
                
                noOptionsMessage={() => 'No buckets are available'}
                options={context.stores.map((store) => ({
                    value: store,
                    label: store,
                }), []) as any}
                autoFocus
                value={tempVal}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && tempVal) {
                        e.preventDefault()
                        send({
                            type: 'setStore',
                            store: tempVal.value,
                        })
                    }
                }}
                onChange={(value) => {
                    setTempVal(value ?? null)
                }}
            />
            <SectionButton
                onClick={(tempVal && (() => send({
                    type: 'setStore',
                    store: tempVal.value,
                }))) || undefined}
            >continue</SectionButton>
        </Section>
    </Modal >
}

export {
    RemoteFolderSelect,
    RemoteStoreSelect,
}
