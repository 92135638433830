import { FileResult } from "../types"

const MAX_PATH_LENGTH = 27

const shortFilename = (path?: string, splitWith = '...') => {
    if (path === undefined || path.length < MAX_PATH_LENGTH) {
        return path
    }
    const shortPath = `${path.slice(0, Math.floor((MAX_PATH_LENGTH - splitWith.length) / 2))}${splitWith}${path.slice(Math.floor((MAX_PATH_LENGTH - splitWith.length) / 2) * -1)}`
    return shortPath
}

const fileToTitle = (protocol: string, file: FileResult, store?: string): string => {
    if (protocol === 'local') {
        return `${protocol}://${store}${file.path}`
    }
    if (file?.meta?.api) {
        return `${protocol}://${file.store}/${file?.ref}`
    }
    return `${protocol}://${store ? `${store}/` : ''}${file?.ref}`
}

export {
    fileToTitle,
    shortFilename,
}
