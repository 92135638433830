import { FC, PropsWithChildren } from "react"
import { Box } from "./Box"

const FullscreenMessage: FC<PropsWithChildren> = ({ children }) =>
    <div
        style={{
            height: '90vh',
        }}
    >
        <Box center fill>
            {children}
        </Box>
    </div>

export {
    FullscreenMessage,
}
