import React from 'react'
import styles from './DartmouthIdent.module.css'
import { useMode } from '../containers/ModeContainer'

interface DartmouthIdentProps {
    horizontal?: boolean
    color?: string 
}

const DartmouthIdent: React.FC<DartmouthIdentProps> = ({ horizontal, color = '#ccc' }) => {
    const { title } = useMode()
    return <div
        style={{
            color,
        }}
        className={horizontal
            ? styles.identContainer
            : styles.identContainerVertical}
    >
        DARTMOUTH <span className={styles.altText}>{title}</span>
    </div>
}

export {
    DartmouthIdent,
}
