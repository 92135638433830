import { FC, PropsWithChildren, ReactElement, useEffect, useState } from "react"
import { config } from "../config"
import { FullscreenMessage } from "../components/FullscreenMessage"
import { Translate } from "./I18nContainer"
import { isBrowserChrome } from "../lib/detectChrome"

const ServiceContainer: FC<PropsWithChildren<{ fallback?: ReactElement }>> = ({ fallback = null, children }) => {
    const [[loaded, errorKey], setServiceStatus] = useState<[boolean, string | null]>([false, null])

    useEffect(() => {
        if (!isBrowserChrome()) {
            setServiceStatus([true, 'invalidBrowser'])
            return
        }
        fetch(`${config.apiServer}/status`).then(async (res) => {
            const result = await res.json()
            if (!result.ok) {
                throw new Error(result?.error ?? 'unknown issue')
            }
            setServiceStatus([true, null])
        }).catch((e) => {
            console.error('[ServiceContainer]', 'failed to get service status', e?.message ?? e)
            setServiceStatus([true, 'serviceUnavailable'])
        })
    }, [])

    if (!loaded) {
        return <FullscreenMessage>
            <Translate id={'loader.serviceProbe'} />
        </FullscreenMessage>
    }

    if (errorKey) {
        return fallback ?? <FullscreenMessage>
            <Translate id={`error.${errorKey}`} />
        </FullscreenMessage>
    }

    return children
}


export {
    ServiceContainer,
}
