const NUMBER_FORMAT = new Intl.NumberFormat("en")

const translations = {
    title: {
        root: 'dartmouth',
        template: 'dartmouth | %s',
        dashboard: 'Dashboard',
        missing: 'Page not found',
        error: 'Error loading page',
    },
    pages: {
        bootcamp: {
            title: 'Bootcamp',
        },
        administration: {
            title: 'Administration',
        },
        browseLocal: {
            title: 'Local Files',
        },
        browseS3: {
            title: 'S3 Bucket',
        },
        apiBrowser: {
            title: 'Data Browser',
        },
        systemStatus: {
            title: 'Monitor',
        },
    },
    toolPages: {
        admin: {
            title: 'admin',
        },
        jobs: {
            title: 'jobs',
        },
    },
    general: {
        scans: 'scans',
        batches: 'batches',
        scan: 'scan',
        batch: 'batch',
        none: 'none',
    },
    data: {
        columns: {
            reference: 'reference',
            batch_id: 'batch id',
            categories: 'categories',
            scans: 'scans',
        },
    },
    user: {
        login: 'login',
        logout: 'logout',
        loading: 'Loading...',
        profile: 'Profile',
    },
    error: {
        invalidBrowser: 'Invalid browser; Please use Chrome',
        missingPage: 'Page not found :(',
        unknownIssue: 'Unknown issue',
        serviceUnavailable: 'Service Failure; System Unavailable.',
    },
    loader: {
        serviceProbe: 'Checking system service status...'
    },
    buttons: {
        reject: 'reject',
        approve: 'approve',
        close: 'close',
        reset: 'reset',
        next: 'next',
        viewBatches: 'view batches',
        viewScans: 'view scans',
        generateJob: 'Generate',
    },
    status: {
        pending: 'pending',
        approved: 'approved',
        rejected: 'rejected',
        reserved: 'reserved',
        imported: 'imported',
        revoked: 'revoked',
        draft: 'draft',
    },
    intro: {
        welcome: 'Welcome, {{username}}!',
        text: 'I hope you find this useful.',
        share: 'A link was shared with this application.',
    },
    cookies: {
        consentTitle: 'We value your privacy',
        consentBody: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept Cookies", you consent to our use of cookies. ',
        consentAcceptButton: 'Accept Cookies',
        consentDeclineButton: 'Decline Cookies',
    },
    format: {
        user: {
            label: ({ name, email }: { name: string, email: string }) =>
                `${name} <${email}>`
        }
    },
    modes: {
        project: {
            approvingFile: 'Approval Mode',
            viewingFile: 'Labelling Mode',
        },
    },
    threats: {
        threatCount: (count: number) =>
            `${NUMBER_FORMAT.format(count)} threat${count>1?'s':''}`
    },
}

export default translations
