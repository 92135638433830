import { FC, useEffect, useMemo } from "react"
import { Box } from "./Box"
import styles from './LoadingPane.module.css'
import { useFile } from "../containers/FileContainer"
import { Translate } from "../containers/I18nContainer"

const ApprovalPane: FC = () => {
    const { context, send } = useFile()
    const selection = useMemo(() =>
        context.groundTruths
            .find((gt) =>
                gt.id === context.selection?.id) ?? null
        , [context.selection, context.groundTruths])

    return <div
        key={'fullLayout'}
        className={context.selection?.id !== undefined
            ? styles.fullLayout
            : styles.fullLayoutHidden}
        onClick={() => {
            send({
                type: 'setSelection'
            })
        }}
    >
        <Box key={'scanView'} fill flex center>
            <div className={styles.paneBody}>
                <div>{selection?.user_label ?? ''}</div>
                <button
                    onClick={() => {
                        send({
                            type: 'updateSelectionState',
                            status: 'approved'
                        })
                    }}
                ><Translate id={'buttons.approve'} wrapper={false} /></button>
                <button
                    onClick={() => {
                        send({
                            type: 'updateSelectionState',
                            status: 'rejected'
                        })
                    }}
                ><Translate id={'buttons.reject'} wrapper={false} /></button>
            </div>
        </Box>
    </div >
}

export {
    ApprovalPane,
}