import { createContext, FC, PropsWithChildren, useContext } from "react"
import { useAuthenticationOptional } from "./AuthorisationContainer"
import { UserRoles } from "../types"
import { Helmet } from "react-helmet"


interface ModeProps {
    title: string
    mode?: 'admin' | 'user'
    adminRole?: UserRoles
}

type ModeState = {
    isAdmin: boolean
} & ModeProps

const ModeContext = createContext<ModeState | undefined>(undefined)

const ModeContainer: FC<PropsWithChildren<ModeProps>> = ({ children, mode = 'user', title, adminRole = UserRoles.Admin }) => {
    const auth = useAuthenticationOptional()

    return <ModeContext.Provider value={{
        mode,
        title,
        isAdmin: auth?.roles?.includes(adminRole) ?? false,
    }}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </ModeContext.Provider>
}

const useMode = (): ModeState => {
    const context = useContext(ModeContext)
    if (context === undefined) {
        throw new Error('element is not in FileContainer context')
    }
    return context
}

export {
    ModeContainer,
    useMode,
}
