import z from 'zod'
import type { FileResult, StorageMode } from '../types'

const APP_STATE_STORAGE_KEY = 'dartmouth-app-viewer-state'
const ZAppStateStorage = z.object({
    file: z.record(z.string(), z.any()).optional(),
    basePath: z.string().optional(),
    protocol: z.enum(['google', 'local', 's3', 'api']).optional(),
    store: z.string().optional(),
    time: z.number(),
})
type AppStateStorage = {
    file?: FileResult
    basePath?: string
    protocol?: StorageMode
    store?: string
    time: number
}

var lastState: AppStateStorage = {
    file: undefined,
    basePath: undefined,
    protocol: undefined,
    store: undefined,
    time: 0,
}

const saveApplicationState = (basePath?: string, file?: FileResult, protocol?: StorageMode, store?: string) => {
    try {
        const parsedState = ZAppStateStorage.parse({
            file,
            basePath,
            protocol,
            store,
            time: new Date().getTime(),
        })
        if (parsedState.file?.ref === lastState.file?.ref && parsedState.basePath === lastState.basePath) {
            return
        }
        lastState = Object.assign({},parsedState) as never as AppStateStorage
        localStorage.setItem(APP_STATE_STORAGE_KEY, JSON.stringify(parsedState))
    } catch(e: any) {
        console.error('saveApplicationState failed', e)
        return
    }
}

const loadApplicationState = (): AppStateStorage | Partial<AppStateStorage> => {
    try{
        const previousState = JSON.parse(localStorage.getItem(APP_STATE_STORAGE_KEY) ?? '{}')
        return ZAppStateStorage.parse(previousState) as AppStateStorage | Partial<AppStateStorage>
    } catch(e: any) {
        return {
            
        }
    }
}
export type {
    AppStateStorage
}
export {
    loadApplicationState,
    saveApplicationState,
}
