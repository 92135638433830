import { FileSidebarMenu } from "../components/SidebarMenu"
import { ProjectApplicationLayout } from "../layouts/FileApplicationLayout"
import { MainFileLayout } from "../layouts/MainFileLayout"
import { FileSidebarLayout } from "../layouts/SidebarLayout"

import styles from './Layout.module.css'

const ProjectViewer = ({ mode = 'user' }: { mode?: 'user' | 'admin' }) => {
    return <ProjectApplicationLayout mode={mode}>
        <FileSidebarMenu
            key={'sidebarMenuLayout'}
        />
        <div
            key={'mainLayout'}
            className={styles.mainContainer}
        >
            <FileSidebarLayout
                key={'sidebarContent'}
            />
            <MainFileLayout
                key={'mainContent'}
            />
        </div>
    </ProjectApplicationLayout>
}

export {
    ProjectViewer,
}
