import React, { createRef, useEffect, useState } from 'react'
import { TreeView } from '../components/TreeView'
import { ApplicationState, SidebarMode } from '../machines/fileApplicationMachine'
import styles from './SidebarLayout.module.css'
import { BsSearch } from 'react-icons/bs'
import { useApplication } from '../containers/FileApplicationContainer'
import { useAuthentication } from '../containers/AuthorisationContainer'

const SidebarProjectLayout: React.FC<ApplicationState> = ({ currentState, context, send }) =>
    <>
        <div
            className={styles.sidebarTitle}
        >Projects</div>
        <div
            key={'fileTreeContainer'}
            className={styles.fileTreeContainer}
        ></div>
    </>

const SidebarFolderLayout: React.FC<ApplicationState> = ({ currentState, context, send }) =>
    <>
        {/* <div
            className={styles.sidebarTitle}
        >Files</div> */}
        <div
            key={'fileTreeContainer'}
            className={styles.fileTreeContainer}
        >
            {currentState === 'loadingPath' || currentState === 'pathSelect' || currentState === 'loadingStore' || currentState === 'storeSelect'
                ? <div>loading...</div>
                : context?.tree !== undefined
                    ? <TreeView
                        file={context.file}
                        key={context.basePath}
                        tree={context.tree}
                        basePath={context.basePath}
                        selectedFolderPath={context.path ?? ''}
                        setSelectedFolderPath={(path) => send({
                            type: 'selectPath',
                            path,
                        })}
                        onFileSelect={(file) => send({
                            type: 'selectFile',
                            file,
                            protocol: context.protocol,
                        })}
                    />
                    : <div style={{
                        fontWeight: 300,
                        fontSize: '1.5em',
                    }}>please select a folder</div>}
        </div>
        <div
            key={'folderSelectButton'}
            className={
                currentState === 'loadingPath' || currentState === 'pathSelect' || currentState === 'loadingStore'
                    ? styles.folderSelectButtonDisabled
                    : context?.path
                        ? styles.folderSelectButton
                        : styles.folderSelectButtonHighlight
            }

            onClick={() => send({
                type: 'browsePath',
            })}
        >Select Folder</div>
    </>

const SidebarUserLayout: React.FC<ApplicationState> = ({ currentState, context, send }) => {
    const auth = useAuthentication()
    return <>
        <div
            className={styles.sidebarTitle}
        >Profile</div>
        <div
            key={'userProfileContainer'}
            className={styles.userProfileContainer}
        >
            <div className={styles.userProfileRow}>
                <div><b>sid</b></div>
                <div>{auth.profile.sub}</div>
            </div>
            <div className={styles.userProfileRow}>
                <div><b>name</b></div>
                <div>{auth.profile.family_name}, {auth.profile.given_name}</div>
            </div>
            <div className={styles.userProfileRow}>
                <div><b>username</b></div>
                <div>{auth.profile.preferred_username}</div>
            </div>
            <div className={styles.userProfileRow}>
                <div><b>email</b></div>
                <div>{auth.profile.email}</div>
            </div>
            <div className={styles.userProfileRow}>
                <div><b>roles</b></div>
                <div
                    style={{
                        whiteSpace: 'wrap',
                        maxWidth: '19em',
                    }}
                >{auth.profile.role.join(', ')}</div>
            </div>
        </div>
        <div
            key={'userLogoutButton'}
            className={styles.folderSelectButton}

            onClick={() => {
                if (auth?.user) {
                    auth.user.signoutRedirect({
                        post_logout_redirect_uri: window.location.href,
                        redirectMethod: "replace"
                    })
                }
            }}
        >Logout</div>
    </>
}

const SidebarSearchLayout: React.FC<ApplicationState> = ({ currentState, context, send }) => {
    return <>
        <div
            className={styles.sidebarTitle}
        >Search</div>
        <div
            key={'fileTreeContainer'}
            className={styles.fileTreeContainer}
            style={{
                gap: '0.5em',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            
        </div>
    </>
}

const SidebarListLayout: React.FC<ApplicationState> = ({ context, send }) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [searchMode, setSearchMode] = useState<boolean>(false)
    const inputRef = createRef<HTMLInputElement>()

    if (context.type !== 'application') {
        return
    }

    return <>
        <div
            key={'sidebarHeader'}
            className={styles.sidebarTitle}
        >
            <div className={styles.sidebarHeaderElement}>
                {searchMode
                    ? <input
                        className={styles.searchTermInput}
                        value={searchTerm}
                        ref={inputRef}
                        type={'text'}
                        placeholder={'search here...'}
                        onChange={({ target }) =>
                            setSearchTerm(target.value)}
                        autoFocus
                    />
                    : <div>Scans</div>}
            </div>
            <div
                className={styles.reportHeaderSearchButton}
                onClick={() => {
                    let currentMode = searchMode
                    setSearchMode(!searchMode)
                    if (currentMode) {
                        setSearchTerm('')
                    }
                }}
            >
                <BsSearch fill={searchMode ? '#00f' : undefined} />
            </div>
        </div>

        <div
            className={styles.rowsContainer}
        >
            {/* <div className={styles.rowHeader}>
                <div className={styles.rowHeaderLabel}>path</div>
                <div className={styles.rowHeaderOther}>status</div>
            </div> */}
            {context.report?.filter((reportRow) => {
                if (searchTerm.length > 0 && searchMode) {
                    return reportRow.output.includes(searchTerm.trim())
                }
                return true
            }).map((reportRow, i) => {
                const file = reportRow.result!
                const label = reportRow.output.replaceAll('\\', '/')
                const isActive = file.ref === context.file?.ref
                const hasResult = reportRow.result !== undefined

                return <div
                    key={`report_row_${file.ref}`}
                    className={!hasResult
                        ? styles.rowItemInactive
                        : isActive
                            ? styles.rowItemSelected
                            : styles.rowItem}
                    onClick={!isActive && hasResult
                        ? () => send({
                            type: 'selectFile',
                            file: file,
                            protocol: context.protocol,
                        })
                        : undefined}
                >
                    <div className={styles.rowItemLabel}>{label}</div>
                    {/* <div className={styles.rowItemOther}>
                        {!noThreat
                            ? `${reportRow.threats_detected} ${reportRow.threats_detected > 1 ? 'threats' : 'threat'}`
                            : 'no threat'}
                    </div> */}
                </div>
            })}
        </div>
    </>
}

const SidebarErrorsLayout: React.FC<ApplicationState> = ({ context, send }) =>
    <>
        <div
            className={styles.sidebarTitle}
        >Issues</div>
        <div
            className={styles.rowsContainer}
        >
            {context.errors.map((error: any, i) =>
                <div className={styles.rowItem} key={`error_${i}`}>
                    {error?.message ?? 'unknown'}
                </div>)}
        </div>
        <div
            key={'folderSelectButton'}
            className={context.errors.length
                ? styles.folderSelectButtonHighlight
                : styles.folderSelectButton}
            onClick={() => send({
                type: 'clearErrors',
            })}
        >Clear Issues</div>
    </>

const SidebarView: React.FC<ApplicationState & { mode: SidebarMode }> = ({ context, send, currentState, mode }) => {
    if (mode === SidebarMode.Search) {
        return <SidebarSearchLayout
            key={'sidebarWrapper'}
            context={context}
            currentState={currentState}
            send={send}
        />
    } else if (mode === SidebarMode.Profile) {
        return <SidebarUserLayout
            key={'sidebarWrapper'}
            context={context}
            currentState={currentState}
            send={send}
        />
    } else if (mode === SidebarMode.Folder) {
        return <SidebarFolderLayout
            key={'sidebarWrapper'}
            context={context}
            currentState={currentState}
            send={send}
        />
    } else if (mode === SidebarMode.Errors) {
        return <SidebarErrorsLayout
            key={'sidebarWrapper'}

            context={context}
            currentState={currentState}
            send={send}
        />
    } else if (mode === SidebarMode.List) {
        return <SidebarListLayout
            key={'sidebarWrapper'}

            context={context}
            currentState={currentState}
            send={send}
        />
    } else if (mode === SidebarMode.Project) {
        return <SidebarProjectLayout
            key={'sidebarWrapper'}

            context={context}
            currentState={currentState}
            send={send}
        />
    }
    return null
}

const useSidebarModeCache = (currentMode: SidebarMode): [SidebarMode, boolean] => {
    const [[mode, visible], setModeCache] = useState<[SidebarMode, boolean]>([currentMode, currentMode !== SidebarMode.None])
    useEffect(() => {
        if (currentMode === SidebarMode.None) {
            setModeCache(([_mode]) =>
                [_mode ?? SidebarMode.None, false])
            return
        }
        setModeCache([currentMode, true])
    }, [currentMode])
    return [mode, visible]
}

const FileSidebarLayout: React.FC = () => {
    const { context, currentState, send } = useApplication()
    const [mode, visible] = useSidebarModeCache(context.view.sidebar)

    return <div
        key={'sidebarContainer'}
        className={visible
            ? styles.sidebarWrapperVisible
            : styles.sidebarWrapper}
    >
        <SidebarView
            mode={mode}
            context={context}
            send={send}
            currentState={currentState}
        />
    </div>
}


export {
    FileSidebarLayout,
}
