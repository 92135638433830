import React from 'react'
import { BareModal } from './Modal'
import { JobPage } from '../pages/JobToolPage'
import { AdminPage } from '../pages/AdminToolPage'
import { useMode } from '../containers/ModeContainer'

const NothingSelected = ({ prompt }: { prompt: boolean }) => {
    const { isAdmin, mode } = useMode()

    if (prompt) {
        if (isAdmin && mode === 'admin') {
            return <BareModal behind>
                <AdminPage />
            </BareModal>
        }

        return <BareModal behind>
            <JobPage />
        </BareModal>
    }

    return null
}

export {
    NothingSelected,
}
