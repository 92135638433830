
const gConfig: Config = (window as any).config;

type Config ={
    authority: string
    redirectUri: string
    apiServer: string
    clientId: string
    scopes: string[]
    responseType: string
    isProduction: boolean
    isLocal: boolean
}

// This is injected in the index.html file via config.js, which can be supplied to the docker container via the CONFIG_JS environment variable
// export const config: Config = {
//     domain: gConfig?.domain ?? 'http://localhost:5000',
//     adminDomain: gConfig?.adminDomain ?? 'http://localhost:5004',
//     emulatorDomain: gConfig?.emulatorDomain ?? 'http://localhost:5043',
//     authority: gConfig?.authority ?? 'https://admin-node:8443/keycloak/realms/pangiam',
//     clientId: gConfig?.clientId ?? 'pangiam-app',
//     redirectUri: gConfig?.redirectUri ?? 'http://localhost:3000',
//     deployMode: gConfig?.deployMode ?? 'single-box',
//     showDashboard: true,
//     // apiKey: gConfig?.apiKey ?? "dev-key"
// }

const IS_LOCAL: boolean = /https:\/\/localhost:/.test(window.location.toString())

const BASE_DOMAIN = window.location.hostname
const IS_PRODUCTION = Boolean(BASE_DOMAIN.toLowerCase() === 'bootcamp.dartmouth-prod.cloud')


export const config: Config = {
    // AUTH
    authority: gConfig?.authority ?? 'https://keycloak.prj-pgm-staging-bootcamp.project-dartmouth.com/realms/bootcamp',
    clientId: gConfig?.clientId ?? 'bootcamp',
    redirectUri: gConfig?.redirectUri ?? IS_LOCAL
        ? 'https://localhost:3000'
        : `https://${BASE_DOMAIN}`,
    scopes: ['profile', 'email', 'openid', 'roles'],
    responseType: 'code',
    // API
    apiServer: IS_LOCAL
        ? 'http://localhost:3001'
        : `https://api.${BASE_DOMAIN}`,
    // ENV
    isProduction: IS_PRODUCTION,
    isLocal: IS_LOCAL,
}
