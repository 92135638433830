import { UserRoles } from "../types"

const AdminGroup: UserRoles[] = [
    UserRoles.Admin,
]

const ViewerGroup: UserRoles[] = [
    ...AdminGroup,
    UserRoles.Admin,
    UserRoles.LabellerAdmin,
    UserRoles.ModelTrainer,
]

const LabellerGroup: UserRoles[] = [
    ...ViewerGroup,
    UserRoles.Labeller,
]


const AllGroups: UserRoles[] = Array.from(new Set([
    ...AdminGroup,
    ...ViewerGroup,
    ...LabellerGroup,
])) as UserRoles[]

export {
    LabellerGroup,
    ViewerGroup,
    AllGroups,
    AdminGroup,
}
