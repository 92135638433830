const NUMBER_FORMAT = new Intl.NumberFormat("en")

const translations = {
    title: {
        root: 'dartmouth',
        dashboard: 'Dashboard',
        missing: 'Page not found',
        error: 'Error loading page',
    },
    pages: {
        bootcamp: {
            title: 'Bootcamp',
        },
        administration: {
            title: 'Administration',
        },
        browseLocal: {
            title: 'Local Files',
        },
        browseGCP: {
            title: 'GCP Bucket',
        },
        apiBrowser: {
            title: 'Data Browser',
        },
    },
    toolPages: {
        admin: {
            title: 'Verwaltung',
        },
        jobs: {
            title: 'arbeiten',
        },
    },
    general: {
        scans: 'scans',
        batches: 'batches',
        scan: 'rastern',
        batch: 'Charge',
        none: 'nichts',
    },
    data: {
        columns: {
            reference: 'Referenz',
            batch_id: 'Batch-ID',
            categories: 'Kategorien',
            scans: 'zählen',
        },
    },
    user: {
        login: 'Login',
        logout: 'Logout',
        loading: 'Loading...',
        profile: 'Profile',
    },
    error: {
        missingPage: 'Page not found :(',
        unknownIssue: 'Unknown issue',
    },
    buttons: {
        reject: 'reject',
        approve: 'approve',
        close: 'close',
        reset: 'reset',
        next: 'next',
        viewBatches: 'Batches anzeigen',
        viewScans: 'Scans anzeigen',
        generateJob: 'Generate',
    },
    status: {
        pending: 'ausstehend',
        approved: 'genehmigt',
        rejected: 'abgelehnt',
        reserved: 'reserviert',
        imported: 'importiert',
        revoked: 'widerrufen',
        draft: 'Entwurf',
    },
    intro: {
        welcome: 'Welcome, {{username}}!',
        text: 'I hope you find this useful.',
        share: 'A link was shared with this application.',
    },
    cookies: {
        consentTitle: 'We value your privacy',
        consentBody: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept Cookies", you consent to our use of cookies. ',
        consentAcceptButton: 'Accept Cookies',
        consentDeclineButton: 'Decline Cookies',
    },
    format: {
        user: {
            label: ({ name, email }: { name: string, email: string }) =>
                `${name} <${email}>`
        }
    },
    modes: {
        project: {
            approvingFile: 'Approval Mode',
            viewingFile: 'Labelling Mode',
        },
    },
    threats: {
        threatCount: (count: number) =>
            `${NUMBER_FORMAT.format(count)} threat${count>1?'s':''}`
    },
}

export default translations
