import React, { PropsWithChildren, FC } from 'react'
import styles from './Modal.module.css'

const Modal: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
                {children}
            </div>
    </div>
}

const BareModal: FC<PropsWithChildren<{ behind?: boolean }>> = ({ children, behind }) => {
    return <div className={[
        behind ? styles.modalContainerBehind : '',
        styles.modalContainer].join(' ')}>
        <div className={styles.modalContainerWrapper}>
        {children}
        </div>
    </div>
}

export {
    BareModal,
    Modal,
}
