import { Box } from "../components/Box"
import { Translate } from "../containers/I18nContainer"

const MissingPage = () => {
    return <Box flex fill center>
        <Translate id={'error.missingPage'} />
    </Box>
}

export {
    MissingPage,
}
