const NUMBER_FORMAT = new Intl.NumberFormat("en")

const translations = {
    title: {
        root: 'dartmouth',
        dashboard: 'Dashboard',
        missing: 'Page not found',
        error: 'Error loading page',
    },
    pages: {
        bootcamp: {
            title: 'Bootcamp',
        },
        administration: {
            title: 'Administration',
        },
        browseLocal: {
            title: 'Local Files',
        },
        browseGCP: {
            title: 'GCP Bucket',
        },
        apiBrowser: {
            title: 'Data Browser',
        },
    },
    toolPages: {
        admin: {
            title: '管理',
        },
        jobs: {
            title: '仕事',
        },
    },
    general: {
        scans: 'スキャン',
        batches: 'バッチ',
        scan: 'スキャン',
        batch: 'バッチ',
        none: 'なし',
    },
    data: {
        columns: {
            reference: '参照',
            batch_id: 'バッチ ID',
            categories: 'カテゴリ',
            scans: 'スキャン',
        },
    },
    user: {
        login: 'Login',
        logout: 'Logout',
        loading: 'Loading...',
        profile: 'Profile',
    },
    error: {
        missingPage: 'Page not found :(',
        unknownIssue: 'Unknown issue',
    },
    buttons: {
        reject: 'reject',
        approve: 'approve',
        close: 'close',
        reset: 'reset',
        next: 'next',
        viewBatches: 'バッチを表示',
        viewScans: 'スキャンを表示',
        generateJob: 'Generate',
    },
    status: {
        pending: '保留中',
        approved: '承認済み',
        rejected: '拒否済み',
        reserved: '予約済み',
        imported: 'インポート済み',
        revoked: '取り消し済み',
        draft: '下書き',
    },
    intro: {
        welcome: 'Welcome, {{username}}!',
        text: 'I hope you find this useful.',
        share: 'A link was shared with this application.',
    },
    cookies: {
        consentTitle: 'We value your privacy',
        consentBody: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept Cookies", you consent to our use of cookies. ',
        consentAcceptButton: 'Accept Cookies',
        consentDeclineButton: 'Decline Cookies',
    },
    format: {
        user: {
            label: ({ name, email }: { name: string, email: string }) =>
                `${name} <${email}>`
        }
    },
    modes: {
        project: {
            approvingFile: 'Approval Mode',
            viewingFile: 'Labelling Mode',
        },
    },
    threats: {
        threatCount: (count: number) =>
            `${NUMBER_FORMAT.format(count)} threat${count>1?'s':''}`
    },
}

export default translations
