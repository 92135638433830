import { FC, PropsWithChildren } from "react";
import styles from './ToolPage.module.css'
import { Helmet } from "react-helmet";

const ToolPageHeader: FC<PropsWithChildren> = ({ children }) =>
    <div className={styles.toolPageHeader}>
        {children}
    </div>

const ToolPageBody: FC<PropsWithChildren> = ({ children }) =>
    <div className={styles.toolPageBody}>
        {children}
    </div>

const ToolPageBodySection: FC<PropsWithChildren<{ empty?: boolean}>> = ({ empty, children }) =>
    <div
        className={empty
            ? styles.toolPageBodySectionEmpty
            : styles.toolPageBodySection}
    >
        {children}
    </div>

const ToolPage: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) =>
    <div className={styles.toolPage}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </div>

const ToolPageHeaderButtons: FC<PropsWithChildren> = ({ children }) =>
    <div className={styles.toolPageHeaderButtons}>
        {children}
    </div>

export {
    ToolPage,
    ToolPageHeader,
    ToolPageHeaderButtons,
    ToolPageBodySection,
    ToolPageBody,
}