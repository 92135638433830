import { FC } from 'react'
import styles from './PagePrompt.module.css'

interface PagePromptProps {
    onClick: () => void
    visible?: boolean
    message: string
}

export const PagePrompt: FC<PagePromptProps> = ({ onClick, visible, message }) =>
    <div
        key={'pagePrompt'}
        className={!visible
            ? styles.pagePrompt
            : [styles.pagePrompt, styles.pagePromptVisible].join(' ')}
    >
        <button onClick={onClick}>{message}</button>
    </div>

