import en from './en'
import jp from './jp'
import de from './de'

const createTranslationMap = <T extends { [name: string]: any }>(translationMap: T) =>
    translationMap

const translations = {
    en: createTranslationMap(en),
    jp: createTranslationMap(jp),
    de: createTranslationMap(de),
}


export default translations
